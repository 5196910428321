// Migrated
<template lang="pug">
div
  ResponsiveText.box-title.mb-3
    span {{ $t('charterFilterCharterTags') }}

  .mb-4.pb-md-2.box-caption
    .d-inline.mr-2 –
    .d-inline.font-weight-normal {{ $t('solresorStartPage.selectedCaption') }}

  p.mb-4 {{ $t('solresorStartPage.selectedText') }}
  SlidesBehindSlider
    CharterSimpleBox.my-4(
      v-for="(campaign, idx) in selectedCampaigns"
      :key="idx"
      :text="campaign.text"
      :image="campaign.image"
      :url="campaign.url"
    )
      template(#top-left)
        CampaignTag.d-flex(:tag="{ title: campaign.text, icon: campaign.icon || defaultTagIcon }" size="lg" :tag-style="campaign.style" show-icon)
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  setup () {
    const { urls: localeURLs } = useLocale()

    return {
      localeURLs,
    }
  },

  data () {
    return {
      defaultTagIcon: 'campaign/solresor-icon-empty.png',
    }
  },

  computed: {
    ...mapState(useCharterStore, {
      campaigns: 'tagsFeatured',
    }),

    selectedCampaigns () {
      // Hardcode grouptravel selected cause it's a completely different thing in the system
      const grouptravelSelectedSlide = {
        text: this.$t('solresorGroupTravel.title'),
        image: this.$t('solresorGroupTravel.image'),
        url: this.localeURLs['charterpackagescampaign-gruppresor'],
      }

      return [
        ...this.campaigns.map(campaign => ({
          ...campaign,
          text: campaign.title,
          image: campaign.hero_image,
          url: `${this.localeURLs.charterpackagescampaign}/${campaign.url_name}`,
        })),
        grouptravelSelectedSlide,
      ]
    },
  },
})
</script>
